@charset "UTF-8";
/**
          1. ttm-row

          2. Row-Equal-Height

          3. Bg-Layer

          4. Row-Bg-Image

          5. Col-Bg-Image

          6. highlight-text

          7. Section-Title

          8. Separator

          9. Buttons

          10. Icons

          11. Fid

          12. featured-icon-box

          13. Featured-imagebox

          14. Processbox

          15. Progress-Bar

          16. Testimonial

          17. Client-row

          18. Accordion

          19. Wrap-Form

          20. Tab

          21. Boxes-Spacing

          22. Pricing-Plan

          23. Sidebar

          24. products

**/
/*  --- IMPORT - COLORS ---- */
/*  --- MAIN - COLORS ---- */
/*  --- COLORS - LIGHTS ---- */
/*  --- COLORS - DARKS ---- */
/*  --- END - COLORS ---- */
/*  --- END - IMPORT COLORS ---- */
/* ===============================================
    1.ttm-row
------------------------*/
.ttm-row {
  padding: 90px 0;
}

.ttm-row.row-top-section,
.ttm-row.home5-fid2-section {
  padding: 0 0;
}

.ttm-row.about-section {
  padding: 100px 0 0;
}

.ttm-row.broken-section {
  padding: 0 0;
}

.ttm-row.portfolio-section {
  padding: 144px 0 90px;
}

.ttm-row.services-section {
  padding: 0 0 70px;
}

.ttm-row.only-title-section {
  padding-top: 155px;
  padding-bottom: 155px;
}

.ttm-row.team-section {
  padding-top: 0px;
  padding-bottom: 85px;
}

.ttm-row.row-title-section {
  padding-top: 130px;
  padding-bottom: 215px;
}

.ttm-row.about3-section {
  padding: 90px 0 65px;
}

.ttm-row.contact-section {
  padding: 0 0 100px;
}

.ttm-row.testimonial2-section {
  padding: 90px 0 100px;
}

.ttm-row.blog-section {
  padding: 90px 0 136px;
}

.ttm-row.blog2-section {
  padding: 80px 0 146px;
}

.ttm-row.about2-section {
  padding: 108px 0 47px;
}

.ttm-row.services2-section {
  padding: 90px 0 75px;
}

.ttm-row.desc-only-section {
  padding: 40px 0 25px;
}

.ttm-row.contact-form-section {
  padding: 110px 0 0;
}

.ttm-row.services3-section {
  padding: 90px 0 70px;
}

.ttm-row.fid-section {
  padding-top: 126px;
  padding-bottom: 70px;
}

.ttm-row.partners-section {
  padding: 100px 0 65px;
}

.ttm-row.multi-content-section {
  padding: 90px 0 220px;
}

.ttm-row.company-template-section {
  padding: 0 0;
}

.ttm-row.services3-section {
  padding: 70px 0 168px;
}

.ttm-row.actionbox-section {
  padding: 35px 0 76px;
}

.ttm-row.row-text-section {
  padding: 78px 0 60px;
}

.ttm-row.services-box-section {
  padding: 0 0 95px;
}

.ttm-row.fid2-section {
  padding: 34px 0 65px;
}

.ttm-row.only-title-section.style2 {
  padding-top: 93px;
  padding-bottom: 70px;
}

.ttm-row.processbox2-section {
  padding: 0 0 140px;
}

.ttm-row.map-section {
  padding: 0 0;
}

.ttm-row.carrer-pos-section {
  padding: 0 0 120px;
}

.ttm-row.featured-section {
  padding: 45px 0;
}

.ttm-row.banner-box-section {
  padding: 80px 0 42px;
}

.ttm-row.product-section {
  padding: 0 0 45px;
}

.ttm-row.cart-section {
  padding: 82px 0 130px;
}

.ttm-row.expertise-section {
  padding: 60px 0 20px;
}

.ttm-row.cta-form-section {
  padding: 60px 0 130px;
}

.ttm-row.about4-section {
  padding: 103px 0 0;
}

.ttm-row.desc-only-section.style2 {
  padding: 29px 0 36px;
}

.ttm-row.home4-services2-section {
  padding-bottom: 135px;
}

.ttm-row.home4-services-section {
  padding-bottom: 0;
}

.ttm-row.only-title-section.style3 {
  padding-top: 90px;
}

.ttm-row.only-bgimg-section {
  padding-top: 243px !important;
  padding-bottom: 270px !important;
}

.ttm-row.event-section {
  padding-bottom: 70px;
}

.ttm-row.home4-last-section {
  padding-bottom: 110px;
}

/* ===============================================
    2.Row-Equal-Height
------------------------*/
.row-equal-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.row-equal-height > [class*=col-] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* ===============================================
    3.Bg-Layer
------------------------*/
.ttm-bg {
  position: relative;
}

.ttm-col-bgimage-yes,
.bg-layer-equal-height .ttm-col-bgcolor-yes {
  height: 100%;
  z-index: 9;
}

.ttm-bg-layer,
.ttm-titlebar-wrapper .ttm-titlebar-wrapper-bg-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.ttm-left-span .ttm-col-wrapper-bg-layer {
  width: auto;
  margin-left: -100px;
  right: 0;
}

.ttm-right-span .ttm-col-wrapper-bg-layer {
  width: auto;
  margin-right: -100px;
  right: 0;
}

.ttm-bg.ttm-bgimage-yes > .ttm-bg-layer {
  opacity: 0.9;
}

.layer-content {
  position: relative;
  z-index: 9;
  height: 100%;
}

.bg-layer > .container > .row {
  margin: 0;
}

.bg-layer > .container > .row > [class*=col-],
.bg-layer > .container-fluid > .row > [class*=col-] {
  padding: 0;
}

/* ===============================================
    4.Row-Bg-Image
------------------------*/
.bg-img1 {
  background-image: url(../images/bg-image/row-bgimage-1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-img2 {
  background-image: url(../images/bg-image/row-bgimage-2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-img3 {
  background-image: url(../images/bg-image/row-bgimage-4.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-img4 {
  background-image: url(../images/bg-image/row-bgimage-4.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-img5 {
  background-image: url(../images/machalaep/row-bgimage-5.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-img6 {
  background-image: url(../images/machalaep/row-bgimage-6.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-img8 {
  background-image: url(../images/bg-image/row-bgimage-8.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-img9 {
  background-image: url(../images/bg-image/row-bgimage-9.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-img11 {
  background-image: url(../images/bg-image/row-bgimage-11.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-img12 {
  background-image: url(../images/bg-image/row-bgimage-12.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 80%;
}

.bg-img16 {
  background-image: url(../images/bg-image/row-bgimage-16.jpg);
  background-position: right bottom;
}

.bg-map {
  background-image: url(../images/map.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* ===============================================
    5.Col-Bg-Image
------------------------*/
.ttm-equal-height-image {
  display: none;
}

.col-bg-img-one.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
  background-image: url(../images/machalaep/pago_electronico.png) !important;
  background-position: left center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.col-bg-img-two.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
  background-image: url(../images/machalaep/col-bgimage-2.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.col-bg-img-four.ttm-col-bgimage-yes .filter-blue {
  background-color: #182333;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.col-bg-img-four.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
  background-image: url(../images/machalaep/autoridades.jpeg) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  filter: opacity(0.3);
}

.col-bg-img-six.ttm-col-bgimage-yes .ttm-col-wrapper-bg-layer {
  background-image: url(../images/machalaep/puntos_ubicaciones.png) !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

/* ===============================================
    6.highlight-text
------------------------*/
.highlight-text h2 {
  text-align: left;
  font-size: 90px;
  line-height: 90px;
  font-family: "Poppins", Arial, Helvetica;
  font-weight: 600;
}

.highlight-text {
  background: url(../images/machalaep/highlight-text-bg.jpg) no-repeat;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-position: center center;
}

.max-content {
  max-width: max-content !important;
  width: auto;
}

/* ===============================================
    7.Section-Title
------------------------*/
.section-title {
  position: relative;
}

.section-title h5 {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  margin-bottom: 2px;
  margin-top: 5px;
  color: #8d9297;
}

.section-title h2.title {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 0;
}

.section-title .title-desc {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  margin-bottom: 32px;
}

.section-title .title-header {
  position: relative;
  padding-left: 29px;
  margin-bottom: 29px;
}

.section-title.with-desc .title-header {
  margin-bottom: 25px;
}

.section-title .title-header:after {
  display: block;
  content: "";
  position: absolute;
  background-color: transparent;
  width: 6px;
  top: 0%;
  height: 95%;
  left: 0;
  margin-left: 0;
}

/* style2 */
.section-title.style2 {
  padding-bottom: 42px;
  margin-right: -35px;
}

.section-title.style2.with-seprator {
  padding-bottom: 35px;
}

.section-title.style2 .title-desc:after {
  content: "";
  right: 55%;
  height: 70%;
  position: absolute;
  top: 0%;
  width: 1px;
  border-right: 1px solid rgba(122, 122, 122, 0.34);
}

.ttm-bgcolor-skincolor .section-title.style2 .title-desc:after {
  border-right: 1px solid rgba(255, 255, 255, 0.16);
}

.section-title.style2 .title-header {
  width: 50%;
  padding-right: 60px;
}

.section-title.style2 .title-header,
.section-title.style2 .title-desc {
  display: table-cell;
  vertical-align: middle;
}

.section-title.title-style-center_text,
.section-title.title-style-center_text .title-desc {
  text-align: center;
}

.section-title.title-style-center_text.with-desc .title-header {
  margin-bottom: 47px;
}

.section-title.title-style-center_text .title-header:after {
  width: 70px;
  bottom: -20px;
  height: 6px;
  left: 50%;
  margin-left: -35px;
  top: auto;
}

.section-title.without-sep-line .title-header,
.section-title.title-style-center_text.with-desc .title-header {
  padding-left: 0;
}

.section-title.without-sep-line .title-header:after,
.section-title.title-style-center_text.without-sep-line .title-header:after {
  content: unset;
}

/* row-title*/
.row-title h2.title {
  font-size: 60px;
  line-height: 65px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-weight: 600;
  margin-bottom: 0px;
  padding: 0;
}

.row-title h5 {
  text-align: center;
  font-size: 18px;
  line-height: 52px;
  text-transform: capitalize;
}

/* ===============================================
    8.separator
------------------------*/
.separator .sep-line {
  height: 1px;
  border-top: 1px solid #dbdbdb;
  display: block;
  position: relative;
  top: 5px;
  width: 100%;
}

.ttm-bgcolor-darkgrey .separator .sep-line {
  border-color: rgba(255, 255, 255, 0.14);
}

.ttm-bgcolor-white .separator .sep-line {
  border-color: #f1f1f1;
}

/* ===============================================
    9.Buttons
------------------------*/
.ttm-btn {
  display: inline-block;
  font-size: 16px;
  line-height: normal;
  padding: 11px 32px 11px 31px;
  background: transparent;
  border: 1px solid transparent;
  position: relative;
  font-weight: 600;
  overflow: hidden;
  z-index: 9;
  vertical-align: middle;
  text-transform: capitalize;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.ttm-btn.ttm-icon-btn-left {
  padding-left: 44px;
}

.ttm-btn.ttm-icon-btn-right {
  padding-right: 44px;
}

.ttm-btn.ttm-icon-btn-left i {
  left: 20px;
}

.ttm-btn.ttm-icon-btn-right i {
  right: 20px;
}

.ttm-btn.ttm-icon-btn-right {
  padding-right: 22px;
}

.ttm-btn.ttm-icon-btn-right i {
  right: 2px;
}

.ttm-bgcolor-darkgrey .ttm-btn:hover.btn-inline.ttm-btn-color-skincolor {
  background-color: transparent;
  color: #fff;
}

/** btn-with-icon **/
.ttm-btn i {
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.ttm-btn.ttm-icon-btn-right {
  text-align: right;
}

.ttm-btn.ttm-icon-btn-left {
  text-align: left;
}

/** btn-size-xs **/
.ttm-btn.ttm-btn-size-xs {
  font-size: 11px;
  padding: 8px 20px;
}

.ttm-btn.ttm-btn-size-xs.ttm-icon-btn-right {
  padding-right: 31px;
}

.ttm-btn.ttm-btn-size-xs.ttm-icon-btn-left {
  padding-left: 31px;
}

.ttm-btn.ttm-btn-size-xs.ttm-icon-btn-left i {
  left: 12px;
}

.ttm-btn.ttm-btn-size-xs i {
  font-size: 14px;
  height: 11px;
  line-height: 11px;
  right: 12px;
}

/** btn-size-sm **/
.ttm-btn.ttm-btn-size-sm {
  font-size: 14px;
  padding: 10px 24px 10px 24px;
}

.ttm-btn.ttm-btn-size-sm.ttm-icon-btn-right {
  padding-right: 39px;
}

.ttm-btn.ttm-btn-size-sm.ttm-icon-btn-left {
  padding-left: 39px;
}

.ttm-btn.ttm-btn-size-sm.ttm-icon-btn-left i {
  left: 16px;
}

.ttm-btn.ttm-btn-size-sm i {
  font-size: 11px;
  height: 12px;
  line-height: 12px;
  right: 16px;
}

/** btn-size-md **/
.ttm-btn.ttm-btn-size-md {
  font-size: 14px;
  padding: 13px 30px 13px 30px;
}

.ttm-btn.ttm-btn-size-md.ttm-icon-btn-right {
  padding-right: 48px;
}

.ttm-btn.ttm-btn-size-md.ttm-icon-btn-left {
  padding-left: 48px;
}

.ttm-btn.ttm-btn-size-md.ttm-icon-btn-left i {
  left: 20px;
}

.ttm-btn.ttm-btn-size-md.ttm-icon-btn-right i {
  font-size: 11px;
  height: 11px;
  line-height: 11px;
  right: 20px;
}

/** btn-size-lg **/
.ttm-btn.ttm-btn-size-lg {
  font-size: 20px;
  line-height: 20px;
  padding: 18px 45px;
}

.ttm-btn.ttm-btn-size-lg.ttm-icon-btn-right {
  padding-right: 60px;
}

.ttm-btn.ttm-btn-size-lg.ttm-icon-btn-left {
  padding-left: 60px;
}

.ttm-btn.ttm-btn-size-lg.ttm-icon-btn-left i {
  left: 25px;
}

.ttm-btn.ttm-btn-size-lg.ttm-icon-btn-right i {
  font-size: 20px;
  height: 16px;
  line-height: 16px;
  right: 25px;
}

/** btn-shape **/
.ttm-btn.ttm-btn-shape-round {
  border-radius: 2em;
}

.ttm-btn.ttm-btn-shape-rounded {
  border-radius: 5px;
}

.ttm-btn.ttm-btn-shape-square {
  border-radius: 0;
}

/** btn-style-border **/
.ttm-btn.ttm-btn-style-border {
  background-color: transparent;
  border: 2px solid;
}

.ttm-btn.ttm-btn-color-white.ttm-btn-style-border {
  color: #fff;
  border-color: #fff;
  background: transparent;
}

.ttm-btn.ttm-btn-color-white.ttm-btn-style-border:hover {
  color: #020d26;
  border-color: #fff;
  background-color: #fff;
}

/** btn-inline **/
.ttm-btn.btn-inline {
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 600;
  padding: 0;
  border: 1px solid transparent;
  background-color: transparent;
}

/* videao-btn */
.ttm-video-btn.video-btn-left {
  left: 0;
}

.ttm-video-btn.text-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 165px;
}

.video-btn-left .ttm-play-btn {
  padding-left: 90px;
}

.ttm-video-btn.video-btn-center {
  left: 50%;
  margin-top: -25px;
  position: absolute;
  top: 50%;
}

.ttm-video-btn:hover a {
  opacity: 0.9;
  color: #fff;
}

.ttm-play-btn {
  border-radius: 30px;
  color: #fff;
  font-size: 18px;
  display: block;
  padding: 10px 20px 10px 50px;
  position: relative;
}

.ttm-video-btn .ttm-btn-play,
.ttm-video-btn .ttm-video-btn-play {
  height: 65px;
  width: 65px;
  line-height: 65px;
  border-radius: 50%;
  padding-left: 7px;
  text-align: center;
  font-size: 30px;
  font-variant: normal;
  text-rendering: auto;
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  -webkit-transform: translate(-25%, -10%);
  -ms-transform: translate(-25%, -10%);
  -o-transform: translate(-25%, -10%);
  transform: translate(-25%, -10%);
}

.ttm-btn-play:after,
.ttm-btn-play:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #fda12b;
}

.ttm-btn-play:after {
  z-index: 1;
  width: 105px;
  height: 105px;
  opacity: 0.09;
}

.ttm-btn-play:before {
  z-index: 2;
  width: 94px;
  height: 94px;
  opacity: 0.21;
}

.ttm-play-btn:hover,
.ttm-video-btn span.ttm-btn-play {
  color: #fff;
}

.ttm-video-btn:hover .ttm-btn-play {
  background-color: rgba(253, 161, 43, 0.95);
  color: white;
}

.ttm-video-btn .ttm-video-btn-play:before {
  z-index: 2;
  width: 94px;
  height: 94px;
  opacity: 0.21;
}

.ttm-video-btn .ttm-video-btn-play:before,
.ttm-video-btn .ttm-video-btn-play:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #fff;
}

.ttm-video-btn .ttm-video-btn-play:after {
  z-index: 1;
  width: 105px;
  height: 105px;
  opacity: 0.09;
}

.ttm-video-btn .ttm-video-btn-play:hover:before,
.ttm-video-btn .ttm-video-btn-play:hover:after {
  -webkit-animation: prittyphoto 1.05s infinite;
  -moz-animation: prittyphoto 1.05s infinite;
  -ms-animation: prittyphoto 1.05s infinite;
  -o-animation: prittyphoto 1.05s infinite;
  animation: prittyphoto 1.05s infinite;
}

@-webkit-keyframes prittyphoto {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes prittyphoto {
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
/* @-webkit-keyframes sonarEffect {
    % {
        opacity: .3
    }
    40% {
        opacity: .5
    }
    100% {
        -webkit-transform: scale(1.5);
        opacity: 0
    }
} */
/* ===============================================
    10.Icons
------------------------*/
.ttm-icon {
  margin-bottom: 35px;
  display: inline-block;
  text-align: center;
  border: 2px solid transparent;
  position: relative;
  transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  box-sizing: content-box;
  position: relative;
}

.ttm-icon i {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/** icon-size-xs **/
.ttm-icon.ttm-icon_element-size-xs {
  height: 40px;
  width: 40px;
  line-height: 40px;
}

.ttm-icon.ttm-icon_element-size-xs i {
  font-size: 19px;
}

/** icon-size-sm **/
.ttm-icon.ttm-icon_element-size-sm {
  height: 50px;
  width: 50px;
  line-height: 50px;
}

.ttm-icon.ttm-icon_element-size-sm i {
  font-size: 28px;
}

/** icon-size-md **/
.ttm-icon.ttm-icon_element-size-md {
  height: 58px;
  width: 58px;
  line-height: 58px;
}

.ttm-icon.ttm-icon_element-size-md i {
  font-size: 30px;
}

/** icon-size-lg **/
.ttm-icon.ttm-icon_element-size-lg {
  height: 58px;
  width: 58px;
  line-height: 58px;
}

.ttm-icon.ttm-icon_element-size-lg i {
  font-size: 45px;
}

/** icon-shape **/
.ttm-icon.ttm-icon_element-style-round {
  border-radius: 5px;
}

.ttm-icon.ttm-icon_element-style-rounded {
  border-radius: 50%;
}

.ttm-icon.ttm-icon_element-style-square {
  border-radius: 0;
}

/** icon-style-border **/
.ttm-icon.ttm-icon-color-black.ttm-icon-style-border {
  background-color: transparent;
}

/* ===============================================
    11.Fid
------------------------*/
.inside {
  text-align: center;
  position: relative;
  padding: 29px 15px 25px 15px;
  margin-bottom: 30px;
}

.inside h4 {
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 40px;
  line-height: 45px;
  font-weight: 500;
}

.ttm-fid-view-righticon.inside h4,
.ttm-fid-view-lefticon.inside h4 {
  margin-bottom: 0;
  float: left;
  display: block;
}

.ttm-fid-view-lefticon.inside h4 span:nth-child(2) {
  margin-left: 5px;
}

.inside h3 {
  margin-bottom: 0;
  bottom: -11px;
  width: 100%;
  font-size: 17px;
  line-height: 25px;
  font-weight: 400;
  padding-top: 0;
}

.ttm-fid-view-righticon .ttm-fid-left,
.ttm-fid-view-lefticon .ttm-fid-left {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 44px;
}

.ttm-fid-view-righticon .ttm-fid-icon-wrapper,
.ttm-fid-view-lefticon .ttm-fid-icon-wrapper {
  position: absolute;
  top: 0;
}

.ttm-fid-icon-wrapper i {
  font-size: 40px;
}

.ttm-fid-view-topicon .ttm-fid-icon-wrapper i {
  position: absolute;
  top: 50%;
  left: 50%;
  -khtml-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.ttm-fid-view-lefticon.inside h4 {
  padding-left: 55px;
}

.ttm-fid-view-righticon .ttm-fid-contents,
.ttm-fid-view-lefticon .ttm-fid-contents {
  padding-top: 12px;
  text-align: left;
  border-top: 1px solid #e8e8e8;
  clear: both;
  overflow: hidden;
  margin-top: 15px;
}

.ttm-fid-inner-row > .col-md-3 {
  width: auto;
  max-width: max-content;
}

.ttm-fid-view-lefticon-style2 {
  margin-bottom: 0;
}

.ttm-fid-view-lefticon-style2 .ttm-fid-icon-wrapper {
  position: absolute;
}

.ttm-fid-view-lefticon-style2 .ttm-fid-icon-wrapper i {
  line-height: 70px;
  font-size: 60px !important;
  padding-top: 5px;
}

.ttm-fid-view-lefticon-style2 .ttm-fid-contents {
  padding-top: 0;
  text-align: left;
  clear: both;
  overflow: hidden;
  padding-left: 80px;
}

.ttm-fid-view-lefticon-style2.with-border {
  border-right: 1px solid rgba(255, 255, 255, 0.08);
}

.row.ttm-fid-row-wrapper {
  margin: 0;
}

.row.ttm-fid-row-wrapper .col-md-4 {
  padding: 0;
}

.ttm-fid-view-topicon {
  padding: 30px 0 2px;
  padding-left: 0;
}

.ttm-facts-colum-sep .ttm-fid-view-topicon {
  padding: 5px 0 2px;
  margin-bottom: 14px;
}

.ttm-facts-colum-sep .col-md-3:not(:last-child):after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.23);
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  top: 50%;
  -khtml-transform: translateX(0%) translateY(-50%);
  -moz-transform: translateX(0%) translateY(-50%);
  -ms-transform: translateX(0%) translateY(-50%);
  -o-transform: translateX(0%) translateY(-50%);
  transform: translateX(0%) translateY(-50%);
}

.ttm-fid.ttm-fid-view-topicon .ttm-fid-icon-wrapper {
  margin-bottom: 0;
  width: 122px;
  height: 61px;
  line-height: 61px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.inside.ttm-fid-view-topicon h4 {
  font-size: 44px;
  line-height: 55px;
}

.inside.ttm-fid-view-topicon h3 {
  position: relative;
}

.inside.ttm-fid-view-topicon h3:after {
  content: "";
  left: 50%;
  height: 2px;
  position: absolute;
  top: -9px;
  width: 30px;
  margin-left: -17px;
  background-color: transparent;
}

.ttm-fid-contents sub {
  font-size: 15px;
  font-weight: 400;
  bottom: -0.25em;
}

/* ===============================================
    12.featured-icon-box ( only contents )
------------------------*/
.featured-icon-box {
  position: relative;
}

.featured-icon-box .ttm-icon {
  margin-bottom: 0px;
}

.featured-icon-box.top-icon .featured-content {
  padding-top: 14px;
}

.featured-icon-box .ttm-icon.ttm-icon_element-size-md {
  height: 42px;
  width: 42px;
  line-height: 42px;
}

.featured-icon-box .ttm-icon.ttm-icon_element-fill.ttm-icon_element-size-md {
  height: 72px;
  width: 72px;
  line-height: 72px;
}

.featured-icon-box.left-icon {
  display: table;
}

.featured-icon-box.left-icon .featured-icon:not(.ttm-icon_element-border),
.featured-icon-box.left-icon .featured-icon,
.featured-icon-box.left-icon .featured-content {
  display: table-cell;
  vertical-align: middle;
}

.featured-icon-box.left-icon.icon-align-top .featured-icon {
  vertical-align: top;
}

.featured-icon-box.left-icon .featured-content {
  padding-left: 12px;
}

.featured-title h5 {
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 10px;
  font-weight: 600;
}

.featured-title h6 {
  font-size: 13px;
  line-height: 25px;
  margin-bottom: 0px;
}

.featured-icon-box.iconalign-before-heading {
  padding: 20px 0;
}

.featured-icon-box.iconalign-before-heading .featured-content {
  display: table;
}

.featured-icon-box.iconalign-before-heading .ttm-icon.ttm-icon_element-size-md {
  height: auto;
  width: auto;
}

.featured-icon-box.iconalign-before-heading .featured-content .featured-icon,
.featured-icon-box.iconalign-before-heading .featured-content .featured-title {
  display: table-cell;
  vertical-align: middle;
  padding-left: 20px;
}

body {
  counter-reset: section;
}

.featuredbox-number .ttm-num:before {
  counter-increment: section;
  content: counter(section, decimal-leading-zero) " ";
}

.featured-icon-box.style1 {
  padding: 41px 20px 31px;
}

.featured-icon-box.style2 {
  padding: 0 25px 0 0;
  position: relative;
}

.featured-icon-box.style2 .ttm-icon {
  margin-bottom: 0;
}

.featured-icon-box.style2 .featured-title h5 {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 0;
}

.col-md-4:not(:last-child) .featured-icon-box.style2:after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: #f1f1f1;
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  -khtml-transform: translateX(0%) translateY(-50%);
  -moz-transform: translateX(0%) translateY(-50%);
  -ms-transform: translateX(0%) translateY(-50%);
  -o-transform: translateX(0%) translateY(-50%);
  transform: translateX(0%) translateY(-50%);
}

.featured-icon-box.style3 {
  padding: 0 0 11px 0;
}

.featured-icon-box.style3 .ttm-icon.ttm-icon_element-size-md {
  height: 75px;
  width: 75px;
  margin-right: 10px;
  margin-top: 5px;
}

.featured-icon-box.style3 .ttm-icon.ttm-icon_element-size-md i {
  font-size: 30px;
}

.featured-icon-box.style4 {
  border: 1px solid #f1f1f1;
  padding: 19px 20px;
  margin-bottom: 19px;
  display: block;
}

.featured-icon-box.style4 .ttm-icon {
  margin-bottom: 0;
}

.featured-icon-box.style4 .ttm-icon.ttm-icon_element-size-sm {
  height: 50px;
  width: 50px;
  line-height: 50px;
}

.featured-icon-box.style4 .featured-title h5 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 4px;
}

.featured-icon-box.style4.left-icon .featured-content {
  padding-left: 20px;
}

.featured-icon-box.style6 {
  padding: 45px 40px 45px 80px;
}

.featured-icon-box.style7 {
  margin-top: -5px !important;
  margin-right: 70px !important;
  margin-left: 0px !important;
  border-left-width: 4px !important;
  padding-top: 32px !important;
  padding-left: 7px !important;
  background-color: rgba(255, 255, 255, 0.03) !important;
  border-left-style: solid !important;
  padding-right: 60px;
}

.featured-icon-box.style8 {
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding-top: 36px !important;
  padding-right: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 30px !important;
  width: 100%;
}

.featured-icon-box.style8 .ttm-icon.ttm-icon_element-size-sm {
  height: 58px;
  width: 58px;
  line-height: 58px;
}

.featured-icon-box.style8.left-icon .featured-content {
  padding-left: 24px;
}

.featured-icon-box.style9 {
  border: 1px solid #ebeef0;
  padding: 33px 30px 30px 30px;
  height: 100%;
}

.featured-icon-box.style9 .featured-icon i {
  font-size: 62px;
}

.featured-icon-box.style9 .featured-content {
  padding-top: 5px;
}

/* ===============================================
    13.featured-imagebox ( contents with image)
------------------------*/
.featured-imagebox .ttm-box-view-content-inner {
  position: relative;
  overflow: hidden;
}

.featured-imagebox .featured-content h2.featured-title {
  padding-top: 10px;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 22px;
  line-height: 30px;
  position: relative;
}

.featured-imagebox .featured-content h2.featured-title a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.featured-imagebox .featured-content .category,
.featured-imagebox .featured-content .category a {
  font-size: 15px;
}

.featured-imagebox .featured-icon-box {
  padding: 37px 30px 42px 30px;
  text-align: center;
  background-color: #fff;
}

.featured-imagebox .ttm-box-bottom-content {
  padding: 37px 30px 42px 30px;
  text-align: center;
}

.ttm-box-view-top-image .ttm-box-bottom-content {
  margin-bottom: 10px;
}

.ttm-box-view-top-image .category,
.ttm-box-view-top-image .category a {
  color: #8d9297;
  margin-top: 0;
  font-size: 14px;
}

.ttm-box-view-top-image .ttm-box-bottom-content h2.featured-title {
  font-size: 20px;
  margin-bottom: 0;
  line-height: 25px;
  font-weight: 500;
}

.ttm-box-view-top-image .ttm-box-bottom-content h2.featured-title a {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

/* portfolio */
.featured-imagebox-portfolio {
  position: relative;
  overflow: hidden;
}

.ttm-portfolio-box-view-overlay.ttm-box-view-overlay {
  position: absolute;
  bottom: -30%;
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 35, 51, 0.83);
}

.ttm-portfolio-box-view-overlay.ttm-box-view-overlay .ttm-box-view-content-inner {
  position: absolute;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  border: 1px solid rgba(255, 255, 255, 0.28);
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 15px;
}

.featured-imagebox-portfolio:hover .ttm-portfolio-box-view-overlay.ttm-box-view-overlay {
  opacity: 1;
  bottom: 0;
}

.ttm-portfolio-box-view-overlay .featured-content h2.featured-title:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -22px;
  width: 60px;
  height: 3px;
  margin-left: -25px;
}

.featured-imagebox-portfolio .featured-content-portfolio .category {
  margin-top: -27px;
}

.featured-imagebox-portfolio.ttm-box-view-top-image {
  margin-bottom: 30px;
  overflow: visible;
}

.featured-imagebox-portfolio.ttm-box-view-top-image .ttm-box-bottom-content {
  padding: 17px 25px 24px;
  position: relative;
  text-align: left;
  background-color: #fff;
}

.featured-imagebox-portfolio.ttm-box-view-top-image .ttm-box-bottom-content:before {
  position: absolute;
  bottom: 100%;
  transform: translateX(-50%);
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 15px;
  border-color: transparent transparent #fff transparent;
  z-index: 1;
  top: -14px;
  left: 42px;
}

.featured-imagebox-portfolio .ttm-box-view-overlay {
  position: absolute;
  bottom: -30%;
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  width: 100%;
  height: 100%;
  background-color: rgba(24, 35, 51, 0.83);
}

.featured-imagebox-portfolio:hover .ttm-box-view-overlay {
  opacity: 1;
  bottom: 0;
}

.featured-imagebox-portfolio.ttm-box-view-top-image .featured-iconbox.ttm-media-link a {
  display: inline-block;
  font-size: 18px;
  position: absolute;
  top: 20px;
  right: 20px;
  height: 43px;
  width: 43px;
  line-height: 43px;
  border: 1px solid transparent;
  text-align: center;
  color: #fff;
  background-color: #fda02b;
  border-radius: 50%;
}

.featured-imagebox-portfolio.ttm-box-view-top-image .featured-iconbox.ttm-media-link a {
  top: 50%;
  right: 50%;
  margin-top: -17px;
}

.featured-imagebox-portfolio a.ttm_link {
  line-height: 41px;
  margin-right: -47px;
  float: right;
}

/* team */
.featured-imagebox-team {
  overflow: hidden;
  position: relative;
}

.featured-imagebox-team {
  margin: 5px 0;
  background-color: #fff;
  -webkit-box-shadow: 0 0 25px 0 rgba(41, 61, 88, 0.06);
  -moz-box-shadow: 0 0 25px 0 rgba(41, 61, 88, 0.06);
  box-shadow: 0 0 25px 0 rgba(41, 61, 88, 0.06);
}

.featured-imagebox-team .featured-content.featured-content-team .featured-title {
  padding-top: 26px;
}

.featured-imagebox-team .featured-content.featured-content-team .featured-title h5 {
  font-size: 19px;
  line-height: 23px;
  margin-bottom: -1px;
}

.featured-imagebox-team .featured-content.featured-content-team.category {
  font-size: 13px;
  color: #9dabaf;
}

.featured-imagebox-team .ttm-social-links-wrapper {
  margin: 14px 0;
}

.featured-imagebox-team .ttm-social-links-wrapper ul {
  margin: 0;
  padding: 3px 14px;
}

.featured-imagebox-team .ttm-social-links-wrapper ul li a {
  display: block;
  border-radius: 50%;
  border: 1px solid transparent;
  width: 30px;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 16px;
  text-align: center;
  background-color: transparent;
  color: #8d9297;
}

.featured-imagebox-team.ttm-team-box-view-overlay:not(:hover) .ttm-social-links-wrapper {
  opacity: 0;
}

.featured-imagebox-team.ttm-team-box-view-overlay .featured-content.featured-content-team {
  text-align: center;
  position: relative;
  z-index: 2;
  position: relative;
  margin-top: -40px;
  transform: translateY(40px);
  padding-bottom: 6px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.featured-imagebox-team.ttm-team-box-view-overlay:hover .featured-content.featured-content-team {
  transform: translateY(0);
  background: #fff;
}

.featured-imagebox-team.style2 .featured-thumbnail:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #182333;
  opacity: 0.36;
  z-index: 1;
}

.featured-imagebox-team.style2 .featured-thumbnail img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  width: 100%;
  position: relative;
}

.featured-imagebox-team.style2 .ttm-team-box-view-overlay {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  -webkit-transition: 0.55s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -moz-transition: 0.55s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -ms-transition: 0.55s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -o-transition: 0.55s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: 0.55s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  color: #fff;
  z-index: 1;
  padding: 20px;
}

.featured-imagebox-team.style2 .ttm-social-links-wrapper {
  margin: 0;
  width: 100%;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.featured-imagebox-team.style2 .ttm-social-links-wrapper ul li a {
  font-size: 22px;
  width: 40px;
}

.featured-imagebox-team.style2 .featured-content.featured-content-team {
  padding: 30px;
  padding-bottom: 19px;
  transition-duration: 0.3s;
  color: #fff;
  text-align: center;
}

.featured-imagebox-team.style2:hover .ttm-team-box-view-overlay {
  bottom: 0;
  background-color: #fda12b;
}

/* post */
.featured-imagebox-post {
  background-color: #fff;
  padding: 0;
  margin: 10px 0 20px;
  border: 15px solid #fff;
  -webkit-box-shadow: 0 0 25px 0 rgba(41, 61, 88, 0.06);
  -moz-box-shadow: 0 0 25px 0 rgba(41, 61, 88, 0.06);
  box-shadow: 0 0 25px 0 rgba(41, 61, 88, 0.06);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}

.featured-imagebox-post .featured-content.featured-content-post {
  padding: 15px 17px 11px 17px;
}

.featured-imagebox-post .featured-content.featured-content-post .post-meta span {
  display: block;
  padding: 7px 3px 3px 0;
  font-size: 12px;
}

.featured-imagebox-post .featured-content.featured-content-post .post-meta span i {
  padding-right: 5px;
}

.featured-imagebox-post .featured-content.featured-content-post .post-title h5 {
  margin: 0;
  font-size: 19px;
  line-height: 28px;
}

.featured-imagebox-post:hover {
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.14);
}

.ttm-post-format-video {
  padding-bottom: 69.25%;
  position: relative;
  overflow: hidden;
  padding-top: 25px;
}

.ttm-post-format-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.ttm-box-view-left-image .featured-content,
.ttm-box-view-left-image.featured-imagebox-post .featured-content {
  padding-left: 25px;
}

.ttm-box-view-left-image .ttm-featured-img-left {
  padding: 0;
}

.ttm-box-view-left-image .featured-content .category {
  padding-top: 10px;
  padding-bottom: 10px;
  display: block;
}

.ttm-box-view-left-image .featured-content .category > a {
  display: inline-block;
  border: 1px solid;
  padding: 5px 10px;
  margin-right: 1px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin-bottom: 5px;
}

.ttm-box-view-left-image .featured-content .ttm-box-desc,
.ttm-box-view-left-image .featured-content .featured-desc {
  border-top: 1px solid #f2f2f2;
  margin-top: 10px;
  padding-top: 7px;
}

.featured-imagebox-post.ttm-box-view-left-image .featured-content.featured-content-post .post-meta span {
  padding: 0;
  font-size: 14px;
}

/* event */
.featured-imagebox-event .featured-content-event {
  padding: 24px 30px 30px;
  background-color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.featured-imagebox-event .ttm-box-meta {
  display: table;
}

.ttm-event-meta-dtend,
.ttm-event-meta-dtstart {
  font-weight: 500;
}

.featured-imagebox-event .featured-content-event .ttm-event-meta-dtstart,
.ttm-box-meta .ttm-event-meta-item span.sep {
  display: none;
}

.ttm-box-meta .ttm-event-meta-item,
.ttm-box-meta .tribe-events-vanue {
  display: table-cell;
  float: left;
}

.ttm-box-meta .ttm-event-meta-item {
  margin-right: 8px;
  padding-bottom: 5px;
  color: #828c96;
}

.featured-imagebox-event .ttm-box-meta i {
  padding-right: 8px;
  float: left;
  padding-top: 5px;
}

.featured-imagebox-event .ttm-box-post-date {
  top: 20px;
  width: 60px;
  height: 60px;
  left: 35px;
  z-index: 2;
}

/* ===============================================
    14.Processbox
------------------------*/
.ttm-processbox-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding-top: 8px;
}

.ttm-processbox-wrapper .ttm-processbox {
  width: calc(100% / 3);
  padding: 0 15px;
  position: relative;
  text-align: center;
}

.ttm-processbox-wrapper .ttm-processbox .ttm-box-image {
  margin-bottom: 30px;
  padding-top: 12px;
  position: relative;
  display: inline-block;
}

.ttm-processbox-wrapper .ttm-processbox .ttm-box-image:before {
  display: block;
  content: "";
  position: absolute;
  right: -75px;
  top: 50%;
  height: 22px;
  width: 65px;
  background-image: url(../images/process-icon-img1.png);
  background-repeat: no-repeat;
}

.ttm-processbox-wrapper .ttm-processbox img {
  height: 210px;
  width: 210px;
  border-radius: 50%;
  border: 10px solid #fff;
  box-shadow: 0 0 25px 0 rgba(24, 35, 51, 0.09);
}

.ttm-processbox-wrapper .ttm-processbox .process-num {
  margin-right: 5px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  width: 46px;
  height: 46px;
  line-height: 46px;
  border-radius: 50%;
  border: none;
  display: block;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 15px;
  z-index: 10;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}

.ttm-processbox-wrapper .featured-content {
  text-align: center;
  padding: 0 15px;
}

.ttm-processbox-wrapper .ttm-processbox .featured-title h5 {
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 15px;
}

.ttm-processbox-wrapper .ttm-processbox:nth-child(2n) .ttm-box-image:before {
  background-image: url(../images/process-icon-img2.png);
}

.ttm-processbox-wrapper .ttm-processbox:last-child .ttm-box-image:before {
  content: unset;
}

/* ===============================================
    15.Progress-Bar
------------------------*/
.ttm-progress-bar {
  position: relative;
}

.ttm-progress-bar {
  margin: 0 0 15px 0;
}

.ttm-progress-bar:not(:last-child) {
  margin-bottom: 30px;
}

.ttm-progress-bar .progress .progress-bar.progress-bar-color-bar_black {
  background-color: #414141;
}

.ttm-progress-bar h4 {
  top: -35px;
  left: 0;
  font-size: 17px;
  color: #5d6576;
  font-weight: 400;
  margin-bottom: 10px;
  letter-spacing: 1.2px;
}

.ttm-progress-bar i {
  position: absolute;
  font-size: 21px;
}

.ttm-progress-bar .progress {
  background-color: #c0c0c1;
  border-radius: 0;
  height: 7px;
}

.ttm-progress-bar .progress-parcent {
  position: absolute;
  right: 0;
  color: #0a0a0a;
  z-index: 3;
  text-shadow: none;
  font-size: 17px;
  padding: 2px 0;
  border-radius: 0;
  top: 0;
}

.ttm-icon-left-true.ttm-progress-bar h4 {
  margin-left: 35px;
}

.ttm-progress-bar.ttm-icon-left-true {
  margin-bottom: 24px;
}

/* ===============================================
    16.Testimonial
------------------------*/
.testimonials {
  position: relative;
}

.testimonials .testimonial-content .testimonial-caption {
  margin-bottom: 15px;
  margin-top: 8px;
}

.testimonials .testimonial-content h6 {
  font-size: 17px;
  line-height: 27px;
  font-weight: 400;
  margin-bottom: 0;
}

.testimonials .testimonial-content label {
  display: block;
  margin-top: -5px;
  opacity: 0.8;
}

.testimonials .testimonial-content blockquote {
  padding: 0 10px 0 25px;
  font-style: italic;
  font-weight: 400;
  font-size: 17px;
  background-color: transparent;
  line-height: 30px;
  position: relative;
}

.testimonials .testimonial-content blockquote:after {
  display: block;
  height: 90%;
  margin-top: 8px;
  margin-bottom: 0;
  content: "";
  position: absolute;
  left: 1px;
  top: 0;
  width: 4px;
  background-color: #fda12b;
}

.testimonials .testimonial-content .testimonial-caption:after {
  content: "";
  width: 30px;
  display: block;
  height: 2px;
  text-align: center;
  margin: 0 auto;
  margin-top: 6px;
}

.testimonials .testimonial-avatar .testimonial-img img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.testimonials.style2 .testimonial-content {
  text-align: center;
  background: transparent;
  border-left: none;
  font-size: 17px;
  line-height: 31px;
  margin: 0 10px 18px;
  padding: 0 25px;
}

.testimonials.style2 .testimonial-content blockquote {
  padding: 0 25px;
}

.testimonials.style2 .testimonial-avatar {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 13px;
}

.testimonials.style2 .testimonial-img img {
  margin: 0 auto;
  text-align: center;
  width: 118px;
  height: 118px;
  border: 8px solid #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.11);
}

.testimonials.style2 .testimonial-content h6 {
  font-weight: 600;
}

.ttm-ratting-star {
  padding-top: 2px;
  padding-bottom: 7px;
  color: #fff;
}

/* ===============================================
    17.Client-row
------------------------*/
.client-box {
  margin-bottom: 20px;
  padding: 15px 12px 30px;
}

.ttm-client-logo-tooltip {
  position: relative;
}

.ttm-box-view-separator-logo .client-box .client:after {
  border-right: 1px solid #eaeaea;
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  right: -25px;
  top: 0;
}

.ttm-box-view-separator-logo .row .col-sm-4:nth-child(3n+3) .client-box .client:after {
  display: none;
}

.client-box.ttm-box-view-boxed-logo {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 40px;
}

.client-box.ttm-box-view-boxed-logo .ttm-client-logo-tooltip {
  margin-right: 13px;
  margin-left: 13px;
  padding: 25px 12px;
  display: table-cell;
  text-align: center;
  align-items: center;
  vertical-align: middle;
  height: 130px;
}

.client-box.ttm-box-view-boxed-logo .client {
  width: 100%;
  display: table;
}

/* ===============================================
    18.Accordion
------------------------*/
.wrap-acadion .accordion {
  margin-top: 15px;
}

.accordion .toggle .toggle-title {
  font-size: 17px;
  line-height: 1;
  cursor: pointer;
  padding: 14px 20px;
  font-weight: 400;
  margin-bottom: 0;
  position: relative;
}

.accordion .toggle-title a {
  color: #fff;
}

.accordion .toggle-title a i {
  margin-right: 14px;
}

.accordion .toggle.ttm-style-classic .toggle-title {
  border: 1px solid #f7f7f7;
  background-color: transparent;
}

.accordion .ttm-style-classic .toggle-title.active {
  border-color: #f0f0f0;
  background-color: #f8f8f8;
}

.toggle-content p {
  margin-bottom: 0;
}

.toggle-content ul li {
  list-style: disc;
}

.accordion .ttm-style-classic .toggle-title > a,
.accordion .ttm-style-classic.ttm-toggle-title-bgcolor-grey.active .toggle-title > a,
.accordion .ttm-toggle-title-bgcolor-grey.active .toggle-title:after {
  color: #666;
}

.accordion .toggle-title:after.active,
.accordion .toggle:not(.ttm-style-classic) .toggle-title:after.active {
  color: #fff;
}

.accordion .toggle:not(:first-child) .toggle-title {
  margin-top: 10px;
}

.accordion .toggle:not(:last-child):not(.active) .toggle-title {
  margin-bottom: 10px;
}

.accordion .ttm-style-classic.ttm-toggle-title-bgcolor-grey .toggle-title {
  border-color: #f0f0f0;
  background-color: #f8f8f8;
}

.accordion .ttm-style-classic.ttm-toggle-title-bgcolor-grey .toggle-title.active {
  background-color: #f8f8f8;
}

.accordion .toggle .toggle-title:after {
  position: absolute;
  font-family: "themify";
  right: 20px;
  top: 15px;
  content: "";
}

.accordion .toggle.active .toggle-title:after {
  content: "";
  font-family: "themify";
}

.accordion .toggle-content {
  display: none;
  padding: 14px 20px;
  overflow: hidden;
}

.accordion .toggle.ttm-toggle-title-bgcolor-grey .toggle-title:after {
  position: absolute;
  font-family: "themify";
  content: "";
  right: 20px;
  font-size: 16px;
  top: 15px;
}

.accordion .toggle.ttm-toggle-title-bgcolor-grey.active .toggle-title:after {
  font-family: "themify";
  content: "";
}

.accordion .toggle.active .toggle-title:after {
  color: #fff;
}

.accordion .toggle.ttm-toggle-title-bgcolor-grey .toggle-title:after {
  color: #666;
}

.accordion .ttm-style-classic.ttm-toggle-title-border .toggle-title {
  background-color: transparent;
  border: 2px solid #e3e3e3;
}

.accordion .toggle.ttm-style-classic.ttm-toggle-title-border .toggle-title {
  padding: 18px 20px;
}

/* ===============================================
    19.Wrap-Form
------------------------*/
.wrap-form label {
  width: 100%;
  margin: 0;
}

.wrap-form span.text-input {
  margin-bottom: 15px;
  display: block;
  padding-top: 5px;
  position: relative;
}

/* quote-form */
.ttm-quote-form input[type=text],
.ttm-quote-form textarea,
.ttm-quote-form select {
  border: 1px solid #f8f9fa;
  background-color: #f8f9fa;
  width: 100%;
  font-size: 13px;
}

.ttm-quote-form .text-input textarea {
  min-height: 100%;
}

.ttm-quote-form button {
  margin-top: 10px;
}

.ttm-quote-form .text-input input::placeholder,
.ttm-quote-form .text-input textarea::placeholder {
  color: #828282;
}

.ttm-quote-form input[type=submit] {
  padding: 10px 40px;
  margin-top: 5px;
  width: 100%;
}

.wrap-form.ttm-contactform span.text-input i {
  position: absolute;
  left: 0;
  top: 20px;
  opacity: 0.9;
  font-size: 16px;
  z-index: 1;
}

.wrap-form.ttm-contactform span.text-input input,
.wrap-form.ttm-contactform span.text-input textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #eaeaea;
  border-radius: 0;
  padding-left: 30px;
  padding-bottom: 9px;
}

.wrap-form.ttm-contactform input[type=submit] {
  margin-top: 20px;
}

/* ===============================================
    20.Tab
------------------------*/
.ttm-tabs {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ttm-tabs .section-title {
  padding-bottom: 0;
}

.ttm-tabs ul {
  margin: 0;
  padding: 0;
}

.ttm-tabs.style2 ul {
  display: block;
  margin-bottom: 35px;
}

.ttm-tabs ul.tabs li {
  display: inline-block;
}

.ttm-tabs ul.tabs li a {
  display: block;
  cursor: pointer;
  padding: 4px 20px;
  margin-bottom: 5px;
  line-height: 22px;
  width: auto;
  position: relative;
  z-index: 3;
  border-radius: 3px;
  color: #182333;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.gallery-section .ttm-tabs ul.tabs li:not(.active) a {
  color: #131313;
}

.ttm-tabs ul.tabs li a i {
  font-size: 18px;
  margin-right: 9px;
  position: relative;
  top: 2px;
}

.ttm-tabs ul.tabs li a span {
  font-size: 18px;
  font-weight: normal;
}

.tab-padding-box {
  padding-top: 105px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 70px;
}

.ttm-tabs .content-tab {
  margin-top: 20px;
  width: 100%;
}

.ttm-tabs .content-tab h6 {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 29px;
  padding-bottom: 7px;
}

.ttm-tabs.style2 .tabs {
  margin-bottom: 30px;
  padding-left: 0px;
}

.ttm-tabs.style2.full-width .tabs {
  padding-left: 10px;
}

.ttm-tabs.style2 .content-tab {
  padding: 0;
}

.ttm-tabs.style2 .row.ttm-boxes-spacing-10px {
  padding: 0;
}

.ttm-tabs.style2 ul.tabs li {
  margin-right: 7px;
  background: transparent;
  padding: 0;
  margin-bottom: 5px;
}

.ttm-tabs.style2 ul.tabs li a {
  display: block;
  cursor: pointer;
  padding: 8px 25px;
  line-height: 22px;
  position: relative;
  z-index: 3;
  color: #1c1c1c;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: transparent;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
}

/* ttm-tab-style-vertical */
.ttm-tabs.ttm-tab-style-vertical .content-tab {
  padding: 0;
  margin: 0;
}

.ttm-tabs.ttm-tab-style-vertical ul.tabs li {
  margin-top: 1px;
  width: 100%;
  display: block;
}

.ttm-tabs.ttm-tab-style-vertical ul.tabs li a {
  background-color: #2a2a2a;
  color: #fff;
  letter-spacing: 1.2px;
  padding: 14px 20px;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 27px;
  margin: 0;
  border-radius: 0;
}

/* ttm-tab-style-horizontal */
.ttm-tabs.ttm-tab-style-horizontal ul {
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
}

.ttm-tabs.ttm-tab-style-horizontal ul.tabs li {
  margin-right: 6px;
  display: inline-block;
}

.ttm-tabs.ttm-tab-style-horizontal ul.tabs li:last-child {
  margin-right: 0;
}

.ttm-tabs.ttm-tab-style-horizontal ul.tabs li a {
  padding: 16px 18px 15px 18px;
  font-size: 15px;
  border-radius: 5px;
  letter-spacing: 1.2px;
  border: none;
  color: #fff;
  display: block;
  text-align: left;
}

.ttm-tabs.ttm-tab-style-horizontal .content-tab img {
  height: 150px;
  width: 150px;
}

.ttm-tab-color-grey.ttm-tab-style-classic ul li {
  margin-top: 3px;
  margin-bottom: 2px;
  margin-left: 3px;
  margin-right: 2px;
}

.ttm-tab-color-grey.ttm-tab-style-classic ul li a {
  font-size: 18px;
  text-transform: capitalize;
  padding: 12px 25px;
  border-top: 3px solid;
  border-radius: 0;
}

.ttm-tabs.ttm-tab-color-grey.ttm-tab-style-classic ul li.active a {
  color: #666;
  border-color: #f0f0f0;
}

.ttm-tab-color-grey.ttm-tab-style-classic ul li a {
  border-color: #e8e8e8;
  background-color: #e8e8e8;
}

.ttm-tabs.ttm-tab-color-grey.ttm-tab-style-classic ul li:hover a {
  background-color: #dcdcdc;
}

.ttm-tabs.ttm-tab-color-grey.ttm-tab-style-classic ul li.active a {
  background-color: #fff;
}

.ttm-tabs.ttm-tab-style-classic ul.tabs li {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
  margin-left: -1px;
  margin-top: -1px;
  margin-bottom: 5px;
}

.ttm-tabs.ttm-tab-style-classic ul.tabs li a {
  padding: 14px 18px 14px 18px;
  font-size: 14px;
  border-color: #ddd !important;
  background-color: #fff;
  border: 1px solid;
  border-radius: 0;
  margin-bottom: 0;
}

.ttm-tabs.ttm-tab-style-classic ul.tabs li.active:after {
  display: block;
  content: "";
  position: relative;
  bottom: 7px;
  left: 50%;
  width: 13px;
  height: 13px;
  border-width: 0 0 1px 1px;
  -webkit-transform: translateX(-50%) rotate(-45deg);
  -moz-transform: translateX(-50%) rotate(-45deg);
  -ms-transform: translateX(-50%) rotate(-45deg);
  -o-transform: translateX(-50%) rotate(-45deg);
  transform: translateX(-50%) rotate(-45deg);
}

.ttm-tabs.ttm-tab-style-classic .content-tab {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: padding 0.2s ease-in-out;
  -o-transition: padding 0.2s ease-in-out;
  transition: padding 0.2s ease-in-out;
}

/* ===============================================
    21.Boxes-Spacing
------------------------*/
.row.ttm-boxes-spacing-10px {
  margin: 0px -1px;
}

.ttm-boxes-spacing-10px .ttm-box-col-wrapper {
  padding-right: 5px;
  padding-left: 5px;
  margin: 0 0 10px;
}

.row.ttm-boxes-spacing-10px.style2 {
  padding: 10px 0 0;
  margin: 0 -5px;
}

.row.ttm-boxes-spacing-5px {
  padding: 10px 5px 0px;
  margin: 0;
}

.ttm-boxes-spacing-5px .ttm-box-col-wrapper {
  padding-right: 2.5px;
  padding-left: 2.5px;
  margin-bottom: 5px;
}

.row.ttm-boxes-spacing-5px.style2 {
  padding: 5px 0 0;
  margin: 0 -5px;
}

/* ===============================================
    22.Pricing-Plan
------------------------*/
.ttm-pricing-plan {
  background-color: #f8f9fa;
  -webkit-border-radius: 0;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 0;
  -moz-background-clip: padding;
  border-radius: 0;
  background-clip: padding-box;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  overflow: hidden;
  padding: 50px 40px 30px;
  margin-right: -15px;
  margin-left: -15px;
}

.ttm-ptablebox-price-w {
  position: relative;
  display: inline-block;
  margin-top: 37px;
  margin-bottom: 35px;
}

.ttm-ptablebox-title {
  font-size: 20px;
  line-height: 30px;
  color: #20292f;
}

.ttm-ptablebox-title h3 {
  color: #182333;
  font-size: 23px;
  font-weight: 600;
}

.ttm-pricing-plan .ttm-ptablebox-price {
  padding-left: 20px;
  font-size: 50px;
  line-height: 40px;
  font-weight: 600;
}

.ttm-pricing-plan .ttm-ptablebox-frequency:before {
  content: "/ ";
}

.ttm-pricing-plan .ttm-ptablebox-cur-symbol-after,
.ttm-pricing-plan .ttm-ptablebox-cur-symbol-before {
  font-size: 20px;
  font-weight: 600;
}

.ttm-pricing-plan .ttm-ptablebox-cur-symbol-before {
  position: absolute;
  top: 0;
}

.ttm-currency-before .ttm-ptablebox-price {
  padding-left: 20px;
  font-size: 50px;
  line-height: 40px;
  font-weight: 600;
}

.ttm-pricing-plan .ttm-ptablebox-frequency {
  color: #8d9297;
  position: relative;
  padding-left: 5px;
  font-style: italic;
  font-weight: 500;
}

.ttm-ptablebox-price,
.ttm-ptablebox-frequency,
.ttm-pricing-plan .ttm-ptablebox-cur-symbol-after,
.ttm-pricing-plan .ttm-ptablebox-cur-symbol-before {
  display: inline-block;
}

.ttm-ptablebox-features .ttm-feature-lines {
  list-style-type: none;
  margin: 0;
  padding: 12px 0 20px 0;
}

.ttm-ptablebox-features .ttm-feature-lines li {
  padding: 0;
  margin: 0;
  padding-top: 12px;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 11px;
}

.ttm-ptablebox-features .ttm-feature-lines li:before {
  font-family: "FontAwesome";
  content: "";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  text-decoration: inherit;
  padding-right: 10px;
}

.price_btn {
  display: block;
  padding-top: 21px;
  text-align: center;
}

.price_btn a {
  width: 100%;
}

.ttm-ptablebox-featured-col {
  box-shadow: 0 0 30px rgba(24, 35, 51, 0.06);
  padding: 50px 40px 68px;
  z-index: 1;
  background-color: #fff;
  overflow: visible;
}

.ttm-pricing-plan .ttm-featured-title {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  width: 100%;
  color: #fff;
  padding: 10px 15px;
  background-color: #182333;
}

/* ===============================================
    23.Sidebar
------------------------*/
.sidebar .widget-area .widget {
  position: relative;
  margin: 40px 0 40px;
}

.sidebar .widget a {
  font-weight: 500;
  font-size: 15px;
}

.sidebar .widget ul:not(.contact-widget-wrapper):not(.widget-menu) > li {
  border-top: 1px solid #f1f1f1;
  padding-bottom: 10px !important;
  padding-top: 10px !important;
}

.sidebar .widget ul > li:last-child {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.sidebar .content-area {
  padding-top: 65px;
  padding-bottom: 55px;
}

.sidebar .sidebar-right,
.sidebar .sidebar-left {
  margin-top: -37px;
  padding-top: 60px;
  padding-bottom: 70px;
  float: right;
}

.sidebar .sep_holder .sep_line {
  border-color: #ebebeb;
}

/*author-widget*/
.ttm-author-widget {
  text-align: center;
}

.ttm-author-widget .author-widget_img img {
  margin-bottom: 20px;
  width: 175px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.ttm-author-widget .author-name {
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.ttm-author-widget .author-widget_text {
  line-height: 30px;
  margin: 0;
  padding: 0 2px;
  padding-bottom: 15px;
}

/*widget-search*/
.sidebar .widget.widget-search {
  position: relative;
}

.sidebar .widget.widget-search .search-form label {
  display: block;
}

.sidebar .widget-search .screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.sidebar .widget.widget-search .search-form .search-submit {
  position: absolute;
  top: 0;
  right: 0;
  text-indent: -999px;
  padding: 0;
  width: 46px;
  height: 46px;
  z-index: 1;
  border-radius: 0 3px 3px 0;
  background-image: url(../images/search-icon.png);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px 16px;
  opacity: 0.5;
}

.sidebar .widget.widget-search .input-text {
  border-width: 1px;
  background-color: #fff;
  border-radius: 0;
  text-indent: 0;
}

/*widget-categories*/
.sidebar .widget_product_categories li,
.sidebar .widget_categories li {
  position: relative;
  margin: 5px 0;
  padding-bottom: 5px !important;
}

.sidebar .widget_product_categories li span,
.sidebar .widget-Categories li span {
  position: absolute;
  right: 0;
  top: 13px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  display: block;
  font-size: 13px;
  font-weight: 400;
}

/** ttm-recent-post-list **/
ul.ttm-recent-post-list > li {
  padding: 15px 0;
}

ul.ttm-recent-post-list > li > a {
  font-size: 15px;
  line-height: 22px;
  display: block;
  font-weight: 400;
}

ul.ttm-recent-post-list > li img {
  display: block;
  width: 70px;
  height: 70px;
  float: left;
  margin-right: 18px;
}

ul.ttm-recent-post-list > li .post-date {
  display: block;
  font-size: 12px;
}

/* tagcloud-widget */
.sidebar .widget .tagcloud a {
  display: inline-block;
  padding: 7px 25px;
  margin: 0 2px 8px;
  border: 2px solid;
  border-radius: 0;
  font-size: 14px !important;
  background-color: transparent;
  font-weight: 500;
}

/* widget-nav-menu */
.widget.widget-nav-menu ul li {
  margin-bottom: 2px;
  padding: 0;
}

.widget.widget-nav-menu ul li a {
  display: block;
  padding: 14px 30px 14px 24px;
  position: relative;
  background-color: #fff;
  z-index: 1;
  font-weight: 500;
  font-size: 15px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.widget.widget-nav-menu ul li a:before {
  content: "";
  width: 4px;
  top: 0;
  left: 0;
  background: #eaeaea;
  height: 100%;
  position: absolute;
  -webkit-transition: background 0.2s linear;
  transition: background 0.2s linear;
  z-index: 1;
}

.widget.widget-nav-menu ul li a:after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: width 0.3s linear;
  transition: width 0.3s linear;
  z-index: -2;
}

.widget.widget-nav-menu ul li:hover a:after,
.widget.widget-nav-menu ul li.active a:after {
  width: 100%;
}

/* contact-widget */
.sidebar .widget-area .widget.contact-widget {
  padding: 25px 25px 20px;
  background-color: #fff;
}

.sidebar ul.contact-widget-wrapper li i {
  position: absolute;
  top: 6px;
  left: 0;
  font-size: 20px;
}

.sidebar ul.contact-widget-wrapper li {
  padding-left: 30px;
  position: relative;
  padding-top: 0;
  padding-bottom: 10px;
  line-height: 29px;
}

/* pagination */
.ttm-pagination {
  display: block;
  margin-bottom: 74px;
  margin-top: 20px;
  text-align: center;
}

.ttm-pagination .page-numbers {
  width: 40px;
  height: 40px;
  line-height: 39px;
  text-align: center;
  display: inline-block;
  background-color: #f8f9fa;
  font-size: 15px;
  font-weight: 600;
  border: 1px solid #f8f9fa;
  border-radius: 0;
  margin: 0 3px;
  padding: 0;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

/* ===============================================
    24.Products
------------------------*/
.ttm-product-box {
  margin-bottom: 35px;
}

.ttm-product-box-inner {
  position: relative;
  overflow: hidden;
}

.products .product .ttm-shop-icon {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 60%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  z-index: 2;
  opacity: 0;
  transition: 0.6s;
  z-index: 1;
  display: flex;
  width: auto;
  max-width: 105px;
}

.products .product .ttm-shop-icon:before {
  background: #fff none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.95;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.products .product:hover .ttm-shop-icon {
  opacity: 1;
  top: 50%;
}

.products .product .ttm-shop-icon a {
  display: inline-block;
  width: 53px;
  height: 36px;
  font-size: 15px;
  line-height: 36px;
  text-align: center;
  padding: 0;
  margin: 0;
  background: none;
  font-weight: 500;
  letter-spacing: 1px;
  transition: none;
  padding-left: 0;
  border-radius: 50%;
}

.products .product .search-btn {
  position: relative;
}

.products .product .search-btn:before {
  position: absolute;
  content: "";
  height: 22px;
  left: -1px;
  top: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  transform: translateY(-50%);
  width: 1px;
}

.products .product .ttm-product-box .ttm-product-content {
  text-align: left;
  padding: 12px 0 11px;
  position: relative;
}

.products .product .ttm-product-box .ttm-product-content .ttm-product-title h2 {
  padding: 0;
  margin: 0;
  font-size: 17px;
  font-weight: 500;
  padding-bottom: 0;
  color: #182333;
  line-height: 20px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.products .product .price {
  margin: 0;
  min-height: 20px;
  font-size: 15px;
  margin-top: 2px;
}

.products .price .product-Price-amount {
  font-size: 18px;
  font-weight: 400;
}

.products .price del .product-Price-amount {
  font-size: 13px;
  text-decoration: line-through;
}

.products .price ins {
  text-decoration: none;
  padding: 2px;
}

.products .product .star-ratings {
  position: absolute;
  right: 0;
  bottom: 9px;
}

.products .star-ratings ul {
  padding: 0;
  margin: 0;
}

.products .star-ratings ul li {
  display: inline-block;
}

.products .star-ratings ul li i {
  font-size: 10px;
}

.products .product .onsale {
  height: 26px;
  width: 52px;
  line-height: 28px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  background: #182333;
  display: inline-block;
  vertical-align: top;
  border-radius: 0;
  padding-top: 0;
  position: absolute;
  top: 20px;
  right: 20px;
  left: 20px;
  z-index: 1;
  margin: -2.5px -5px;
  min-height: auto;
  font-weight: 400;
}

/* product-poster */
.product-poster-content .ttm-bg-highlight-dark,
.product-poster-content .ttm-bg-highlight-skin {
  display: inline-block;
}

.product-poster-content .poster-label-tag {
  padding: 0 28px;
}

.product-poster-content .poster-label-tag {
  text-align: center;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
}

.product-poster-content .poster-label h2 {
  padding: 22px 42px;
}

.product-poster-content .poster-label h2 {
  text-align: center;
  font-size: 72px;
  line-height: 70px;
  color: #fff;
  font-weight: 600;
}

.product-poster-content .poster-text {
  text-align: left;
  font-size: 24px;
  line-height: 28px;
  color: #494e54;
  font-weight: 400;
  margin-bottom: 30px;
  padding-top: 22px;
}

.product-poster-content .poster-amount h4 {
  text-align: left;
  font-size: 70px;
  line-height: 70px;
  font-weight: 600;
}

/* single-product-details */
.ttm-single-product-details {
  background-color: #fff;
  margin-bottom: 30px;
}

.ttm-single-product-info {
  padding: 30px 0px;
}

div.product.ttm-single-product-details div.images {
  width: 48%;
  float: left;
}

div.product.ttm-single-product-details div.summary {
  width: 48%;
  float: right;
}

div.product.ttm-single-product-details .product-gallery__image img {
  height: auto;
  max-width: 100%;
}

.ttm-single-product-details div.images .product-gallery__image:nth-child(n+2) {
  width: 25%;
  display: inline-block;
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

div.product .summary .price .Price-amount {
  font-size: 22px;
}

div.product .summary .product-rating {
  float: right;
  margin-bottom: 15px;
}

.summary .star-rating {
  float: left;
  padding: 0;
  margin: 0;
  margin-right: 5px;
}

.summary .star-rating li {
  display: inline-block;
  font-size: 12px;
}

.product_meta {
  padding-top: 17px;
  border-top: 1px solid #ddd;
  margin-top: 15px;
}

.ttm-single-product-details .product-details__short-description {
  margin-top: 20px;
  margin-bottom: 50px;
}

.summary form.cart {
  margin-top: 30px;
  margin-bottom: 30px;
}

div.product form.cart div.quantity {
  float: left;
  margin: 0 4px 0 0;
}

.quantity .qty {
  height: 41px;
  width: 88px;
  text-align: center;
}

.sku_wrapper {
  margin-right: 10px;
}

.posted_in {
  font-weight: 700;
}

.posted_in a {
  font-weight: 400;
}

.tabs-for-single-products.ttm-tabs {
  padding: 0 30px 30px 0px;
}

div.product ul.tabs li a {
  font-weight: 400;
  color: #515151;
  padding: 13px 30px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  font-size: 16px;
  border-color: #e4e4e4;
  background-color: #f9f9f8;
  border-radius: 3px;
  border: 1px solid #e4e4e4;
  position: relative;
}

div.product ul.tabs li {
  margin: 0 2px;
}

div.product ul.tabs li a {
  color: #515151 !important;
}

div.product ul.tabs li.active a,
div.product ul.tabs li:hover a {
  color: #fff !important;
}

div.product ul.tabs li.active a:before {
  display: block !important;
  content: "";
  width: 0;
  height: 0;
  border: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid;
  position: absolute;
  bottom: -8px;
  left: 50%;
  -khtml-transform: translateX(-50%) translateY(0%);
  -moz-transform: translateX(-50%) translateY(0%);
  -ms-transform: translateX(-50%) translateY(0%);
  -o-transform: translateX(-50%) translateY(0%);
  transform: translateX(-50%) translateY(0%);
}

div.product .ttm-tabs .content-inner h2 {
  font-size: 25px;
}

div.product .ttm-tabs .content-tab {
  padding: 30px 0 0 0;
  width: 100%;
}

.related.products {
  margin-top: 50px;
}

.related.products h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  padding-bottom: 15px;
}

#reviews #comments ol {
  margin: 0;
  padding: 0;
}

#reviews #comments ol.commentlist li img.avatar {
  width: 65px;
}

#reviews #comments ol.commentlist li img.avatar {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  padding: 3px;
  background: #ebe9eb;
  border: 1px solid #5c5459;
  margin: 0;
  box-shadow: none;
}

div.product #reviews .star-rating {
  margin: 0;
  margin-top: 7px;
  float: right;
}

#reviews #comments ol.commentlist li {
  padding: 0;
  margin: 0 0 20px;
  position: relative;
  border: 0;
}

#reviews #comments ol.commentlist li .comment-text {
  border: 1px solid #e4e1e3;
  padding: 1em 1em 0;
  border-radius: 0;
  margin-left: 80px;
}

#reviews #comments ol.commentlist li .comment-text p.meta {
  font-size: 16px;
  color: #828c96;
  margin-bottom: 9px;
}

div.product .Reviews .star-rating {
  margin: 0;
  margin-top: 7px;
  float: right;
}

.comment-form-rating {
  padding-bottom: 20px;
}

.comment-form-rating .stars {
  margin-bottom: 15px;
}

#review_form_wrapper .comment-reply-title {
  font-size: 18px;
  line-height: 20px;
  color: #283d58;
}

#review_form_wrapper .comment-respond p {
  margin: 0 0 10px;
}

#reviews #review_form_wrapper select {
  width: 154px !important;
}

#reviews #comment {
  height: 75px;
}

#review_form_wrapper .comment-respond .form-submit {
  margin-top: 30px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  vertical-align: middle;
  width: 100%;
  margin: 0 0 1.75em;
}

table tbody tr,
table thead tr {
  border: 1px solid #e6e6e6;
}

table tbody th {
  border-right: 1px solid #e6e6e6;
}

table.shop_attributes th {
  width: 150px;
  font-weight: 700;
  padding: 8px;
  border-top: 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
  margin: 0;
  line-height: 1.5;
}

table.shop_attributes td {
  font-style: italic;
  border-top: 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
  margin: 0;
  padding-left: 10px;
  line-height: 1.5;
}

table.shop_attributes td p {
  margin: 0;
  padding: 8px 0;
}

/* Cart */
.ttm-cart-form,
.checkout {
  margin: 0;
  padding: 20px;
  background-color: #fff;
}

.shop_table {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
  border-collapse: separate;
  border-radius: 0px;
}

table.shop_table tbody tr,
table.shop_table thead tr {
  border: 1px solid #e6e6e6;
}

table.shop_table th {
  font-weight: 700;
  padding: 9px 12px;
  line-height: 1.5em;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

table.shop_table td {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 10px;
  vertical-align: middle;
  line-height: 1.5em;
}

table.shop_table .product-thumbnail img {
  width: 32px;
  height: auto;
}

table.shop_table td.actions {
  text-align: right;
}

table.shop_table td.actions .input-text {
  width: 150px;
  float: left;
}

.quantity {
  height: 41px;
  width: 88px;
}

.coupon {
  float: left;
}

.coupon .input-text {
  float: left;
  margin: 0 4px 0 0;
}

.actions button,
.coupon button,
a.checkout-button {
  border: 0;
}

.coupon button,
a.checkout-button,
button.cart_button {
  padding: 15px 30px 15px;
  font-weight: 500;
  font-size: 13px;
  line-height: 1;
  text-align: center;
}

button.cart_button {
  padding: 11px 40px;
}

.cart-collaterals {
  margin-top: 60px;
}

.cart-collaterals .cart_totals {
  float: right;
  width: 48%;
}

.cart_totals h2 {
  margin-bottom: 15px;
  font-size: 28px;
}

a.checkout-button {
  display: block;
}

a.remove {
  display: block;
  font-size: 1.5em;
  height: 1em;
  width: 1em;
  text-align: center;
  line-height: 1;
  border-radius: 100%;
  color: red;
  text-decoration: none;
  font-weight: 700;
  border: 0;
}

a.remove:hover {
  color: #fff;
  background: red;
}

/* checkout */
.form-row .required {
  color: red;
  font-weight: 700;
  border: 0 !important;
  text-decoration: none;
}

form .form-row {
  padding: 3px;
  margin: 0 0 6px;
}

.checkout h3#order_review_heading {
  margin-bottom: 30px;
  margin-top: 15px;
}

.checkout #payment {
  background: #ebe9eb;
  border-radius: 5px;
}

ul.payment_methods {
  text-align: left;
  padding: 1em;
  border-bottom: 1px solid #d3ced2;
  margin: 0;
  list-style: none outside;
}

#payment .payment_box {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 1em;
  margin: 1em 0;
  font-size: 0.92em;
  border-radius: 2px;
  line-height: 1.5;
  background-color: #dfdcde;
  color: #515151;
}

#payment div.form-row {
  padding: 1em;
}

/* ===============================================
    24.event
------------------------*/