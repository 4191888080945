/*  --- MAIN - COLORS ---- */

$color-1: #ff5e14;
$color-2: #fda02b;
$color-3: #fda12b;
$color-4: #0168b7;

/*  --- COLORS - LIGHTS ---- */

$color-white: #fff;
$color-light-1: #dedede;
$color-light-2: #f3f7f9;
$color-light-3: #f5f5f5;
$color-light-4: #f8f9fa;
$color-light-5: #cfd1d2;
$color-light-6: #d7d7d7;
$color-light-7: #f1f1f1;
$color-light-8: #eaeaea;
$color-light-9: #e2e3e5;
$color-light-10: #e4e4e4;
$color-light-11: #e8e8e8;
$color-light-12: #efefef;
$color-light-13: #fafafa;
$color-light-14: #eee;
$color-light-15:#ddd;
$color-light-16:#e5e5e5;
$color-light-17: #dbdbdb;
$color-light-18: #ebeef0;
$color-light-19: #f2f2f2;
$color-light-20: #c0c0c1;
$color-light-21: #f7f7f7;
$color-light-22: #f0f0f0;
$color-light-23: #f8f8f8;
$color-light-24: #e3e3e3;
$color-light-25: #dcdcdc;
$color-light-26: #ebebeb;
$color-light-27: #f9f9f8;
$color-light-28: #ebe9eb;
$color-light-29: #e4e1e3;
$color-light-30: #e6e6e6;
$color-light-31: #d3ced2;
$color-light-32: #dfdcde;

/*  --- COLORS - DARKS ---- */

$color-black: #000;
$color-dark-1: #5d6576;
$color-dark-2: #8d9297;
$color-dark-3: #182333;
$color-dark-4: #9dabaf;
$color-dark-5: #313131;
$color-dark-6: #202020;
$color-dark-7: #2c2c2c;
$color-dark-8: #333;
$color-dark-9: #6e6e6e;
$color-dark-10:#2d2d2d;
$color-dark-11:#020d26;
$color-dark-12:#828c96;
$color-dark-13:#414141;
$color-dark-14:#0a0a0a;
$color-dark-15:#666;
$color-dark-16:#828282;
$color-dark-17:#131313;
$color-dark-18:#1c1c1c;
$color-dark-19:#2a2a2a;
$color-dark-20:#20292f;
$color-dark-21:#494e54;
$color-dark-22:#515151;
$color-dark-23:#5c5459;
$color-dark-24:#283d58;

/*  --- END - COLORS ---- */