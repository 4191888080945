@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?7fcd4105c0cf2bed949b94838d27e6aa") format("truetype"),
url("./flaticon.woff?7fcd4105c0cf2bed949b94838d27e6aa") format("woff"),
url("./flaticon.woff2?7fcd4105c0cf2bed949b94838d27e6aa") format("woff2"),
url("./flaticon.eot?7fcd4105c0cf2bed949b94838d27e6aa#iefix") format("embedded-opentype"),
url("./flaticon.svg?7fcd4105c0cf2bed949b94838d27e6aa#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-add-1:before {
    content: "\f101";
}
.flaticon-add:before {
    content: "\f102";
}
.flaticon-calendar-1:before {
    content: "\f103";
}
.flaticon-calendar-2:before {
    content: "\f104";
}
.flaticon-calendar:before {
    content: "\f105";
}
.flaticon-cancelar:before {
    content: "\f106";
}
.flaticon-delete-1:before {
    content: "\f107";
}
.flaticon-delete:before {
    content: "\f108";
}
.flaticon-diskette:before {
    content: "\f109";
}
.flaticon-double-angle-pointing-to-right:before {
    content: "\f10a";
}
.flaticon-double-right-arrows-angles:before {
    content: "\f10b";
}
.flaticon-edit:before {
    content: "\f10c";
}
.flaticon-eliminar-cruz:before {
    content: "\f10d";
}
.flaticon-facebook-1:before {
    content: "\f10e";
}
.flaticon-facebook-circular-logo:before {
    content: "\f10f";
}
.flaticon-facebook:before {
    content: "\f110";
}
.flaticon-fast-forward:before {
    content: "\f111";
}
.flaticon-file:before {
    content: "\f112";
}
.flaticon-floppy-disk:before {
    content: "\f113";
}
.flaticon-instagram:before {
    content: "\f114";
}
.flaticon-letra-x:before {
    content: "\f115";
}
.flaticon-photo:before {
    content: "\f116";
}
.flaticon-plus-1:before {
    content: "\f117";
}
.flaticon-plus:before {
    content: "\f118";
}
.flaticon-remove:before {
    content: "\f119";
}
.flaticon-trash:before {
    content: "\f11a";
}
.flaticon-twitter-1:before {
    content: "\f11b";
}
.flaticon-twitter:before {
    content: "\f11c";
}
.flaticon-upload-file:before {
    content: "\f11d";
}
.flaticon-user:before {
    content: "\f11e";
}
.flaticon-view:before {
    content: "\f11f";
}
.flaticon-visibility:before {
    content: "\f120";
}
.flaticon-youtube:before {
    content: "\f121";
}
