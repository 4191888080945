/* You can add global styles to this file, and also import other style files */

@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

@import "/src/assets/scss/gap";

.contenedor-list {
  color: white;
  background-color: #0b6097;
  padding: 1rem;
  border-radius: 8px;
}

.center-image {
  width: 100%;
  padding-top: 75.5351681957%;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    object-fit: cover;
  }
}

.image-about-us {
  width: 100%;
  padding-top: 49.5436%;
  position: relative;
  overflow: hidden;
  .owl {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
  }
}

.image-center-2 {
  width: 100%;
  padding-top: 49.5436%;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    object-fit: cover;
  }
}

.swal2-content {
  padding: 0px !important;
}

.obras__img {
  @include gap;
  .obra__img {
    @include gap-separation;
    position: relative;
    width: calc(50% - 0.5rem);
    padding-top: 60%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.glyph-icon {
  font-family: "Flaticon" !important;
  line-height: 1 !important;
  text-align: center !important;
}

p {
  text-align: justify;
}

#totop {
  right: 1rem !important;
}

.close-component {
  font-size: 2rem;
  color: black;
  text-align: right;
  cursor: pointer;
  i {
    border: 1px solid white;
    transition: all 0.5s ease-out;
  }

  i:hover {
    border: 1px solid black;
  }
}

.loading-consulta {
  display: flex;
  justify-content: center;
  .i-l {
    width: 70px;
    img {
      width: 100%;
    }
  }
}

.debts_table_container {
  overflow-x: scroll;
  .debts_table {
    min-width: 920px;
  }
  .table-total {
    min-width: 920px;
    table {
      width: 35%;
    }
  }
}

.list-terms {
  list-style: circle;
}
